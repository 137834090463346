import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "button", "formButton", "termsAccepted", "privacyAccepted"]

  showForm() {
    this.formTarget.classList.remove("d-none");
    this.formTarget.classList.add("d-block");
    this.formButtonTarget.classList.remove("d-none");
    this.formButtonTarget.classList.add("d-block");

    this.buttonTarget.classList.add("d-none");
  }

  checkTerms(event) {
    const termsAccepted = this.termsAcceptedTarget.checked;
    const privacyAccepted = this.privacyAcceptedTarget.checked;

    if (!termsAccepted || !privacyAccepted) {
      event.preventDefault();
      alert("Você precisa aceitar os termos e a política de privacidade para continuar.");
    }
  }
}
