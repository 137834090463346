import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="credit-calculator"
export default class extends Controller {
  static targets = ["slider", "input", "result"];

  connect() {
    noUiSlider.create(this.sliderTarget, {
      start: [5],
      step: 1,
      tooltips: true,
      connect: [true, false],
      range: {
        min: [1],
        max: [30],
      },
      format: {
        to: function (value) {
          return Math.round(value);
        },
        from: function (value) {
          return Number(value);
        },
      },
    });

    this.sliderTarget.noUiSlider.on("update", (values) => {
      const credit = parseFloat(values[0]);
      this.updateValue(credit);
    });
  }

  updateValue(credit) {
    this.inputTarget.value = credit;
    const formattedValue = this.calculateValue(credit);
    this.resultTarget.textContent = `R$: ${formattedValue}`;
  }

  calculateValue(credit) {
    const calculatedValue = credit * parseFloat(this.data.get("creditValue"));

    return new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(calculatedValue);
  }
}
