import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["price", "cycle", "discount"]

  toggle(event) {
    const isChecked = event.target.checked;
    this.updatePrices(isChecked);
  }

  updatePrices(isAnnual) {
    const annualPlans = document.querySelectorAll('.annual-plans');
    const monthlyPlans = document.querySelectorAll('.monthly-plans');

    if (isAnnual) {
      annualPlans.forEach((plan) => plan.classList.remove('d-none'));
      monthlyPlans.forEach((plan) => plan.classList.add('d-none'));
    } else {
      annualPlans.forEach((plan) => plan.classList.add('d-none'));
      monthlyPlans.forEach((plan) => plan.classList.remove('d-none'));
    }
  }
}
