import { add, Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-tests-to-step"
export default class extends Controller {
  connect() {
    this.elems = [];
    const initialTests = JSON.parse(this.element.dataset.initialTests)

    document.addEventListener('turbo:frame-load', this.handleFrameLoad.bind(this));

    initialTests.forEach(test => {
      this._addTestToList(test.id, test.name)
      this._toogleButton(document.querySelector(`a[data-test-id="${test.id}"]`))
      this._updateTestsListCard()
      this._updateTestsList(document.getElementById('added-tests-list'))
      this._updateHiddenInput()
    })
  }

  handleFrameLoad(event) {
    const frame = event.target;

    if (frame.contains(this.element.querySelector('#tests-card-list'))) {
      const buttons = frame.querySelectorAll('a.btn-add-test')
      buttons.forEach(button => {
        const testId = button.dataset.testId
        const existingTest = this.elems.find(elem => elem.id === testId)

        if (existingTest) {
          button.classList.add('btn-danger')
          button.classList.remove('btn-outline-primary')
          button.innerHTML = 'Remover teste'
        } else {
          button.classList.add('btn-outline-primary')
          button.classList.remove('btn-danger')
          button.innerHTML = 'Adicionar teste'
        }
      })

      this._updateHiddenInput()
    }
  }

  toggleItem(event) {
    event.preventDefault()

    const testId = event.currentTarget.dataset.testId
    const testName = event.currentTarget.dataset.testName
    const button = document.querySelector(`a[data-test-id="${testId}"]`)
    const addedTestsList = document.getElementById('added-tests-list')

    this._addTestToList(testId, testName)
    this._remove_pill(testId)
    this._toogleButton(button)
    this._updateTestsListCard()
    this._updateTestsList(addedTestsList)
    this._updateHiddenInput()
  }

  _remove_pill(testId) {
    const pill = document.querySelector(`div[data-test-id="${testId}"]`)

    if (pill) {
      pill.remove()
    }
  }

  _toogleButton(button) {
    button.classList.toggle('btn-outline-primary')
    button.classList.toggle('btn-danger')

    if (button.classList.contains('btn-danger')) {
      button.innerHTML = 'Remover teste'
    } else {
      button.innerHTML = 'Adicionar teste'
    }
  }

  _addTestToList(testId, testName) {
    const existingTest = this.elems.find(elem => elem.id === testId)

    if (existingTest) {
      this.elems = this.elems.filter(elem => elem.id !== testId)
    } else {
      this.elems.push({ id: testId, name: testName })
    }
  }

  _updateTestsListCard() {
    const accordionCards = document.getElementById('accordionCards')
    const relatedTests = document.getElementById('related-tests')

    if (this.elems.length > 0) {
      accordionCards.classList.remove('d-none')
    } else {
      accordionCards.classList.add('d-none')
      relatedTests.classList.add('d-none')
    }

    const addedTestCount = document.getElementById('added-tests-count')
    addedTestCount.innerHTML = this.elems.length
  }

  _updateHiddenInput() {
    const hiddenInputs = document.getElementById('hiddenInputs')
    hiddenInputs.innerHTML = ''

    this.elems.forEach(elem => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'ats_step[test_ids][]'
      input.value = elem.id
      hiddenInputs.appendChild(input)
    })

    if (this.elems.length === 0) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'ats_step[test_ids][]'
      input.value = ''
      hiddenInputs.appendChild(input)
    }
  }

  _updateTestsList(addedTestsList) {
    addedTestsList.innerHTML = ''

    this.elems.forEach(elem => {
      var novoHtml = `
        <li class="d-flex align-items-center justify-content-between" data-test-id="${elem.id}">
          <div>
            <i class="fas fa-arrow-right me-2 small"></i>
            <span>${elem.name}</span>
          </div>
          <div>
            <a href="#!" class="btn btn-link text-danger p-0" role='button' data-test-id="${elem.id}" data-action="click->add-tests-to-step#toggleItem">
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </li>
        <hr>
      `;

      addedTestsList.innerHTML += novoHtml
    })
  }
}
