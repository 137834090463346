import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="tests-search"
export default class extends Controller {
  static targets = ["query"]

  connect() {
    this.queryTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault()

        this.submit()
      }
    })
  }

  submit() {
    const query = this.queryTarget.value
    const url = new URL(window.location.origin + this.element.dataset.url)
    url.searchParams.append('query', encodeURIComponent(query))

    Turbo.visit(`${url}`, { frame: 'search_results' })
  }
}
