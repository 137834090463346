import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["linkedinInput", "cvInput", "profileLink", "linkedinVerification", "initialInstructions"]

  connect() {
    console.log("ProfileSourceForm controller connected")
    this.updateRequiredStates()
    this.checkJobStatus()
  }

  handleLinkedinChange(event) {
    console.log("LinkedIn value changed:", event.target.value)
    const value = event.target.value.trim()
    if (value) {
      this.removeRequired(this.cvInputTarget)
      this.updateProfileLink(value)
    } else if (!this.hasCvFile()) {
      this.addRequired(this.cvInputTarget)
    }
  }

  handleCvChange(event) {
    this.updateCvRequirements()
  }

  handleDrop(event) {
    setTimeout(() => {
      this.updateCvRequirements()
    }, 100)
  }

  updateCvRequirements() {
    const hasCv = this.hasCvFile()
    if (hasCv) {
      this.removeRequired(this.linkedinInputTarget)
    } else if (!this.hasLinkedinValue()) {
      this.addRequired(this.linkedinInputTarget)
    }
  }

  // Helper methods
  hasCvFile() {
    return this.cvInputTarget.files && this.cvInputTarget.files.length > 0
  }

  hasLinkedinValue() {
    return this.linkedinInputTarget.value.trim() !== ''
  }

  updateRequiredStates() {
    if (!this.hasLinkedinValue() && !this.hasCvFile()) {
      this.addRequired(this.linkedinInputTarget)
      this.addRequired(this.cvInputTarget)
    }
  }

  // DOM manipulation helpers
  removeRequired(element) {
    element.removeAttribute('required')
    const wrapper = element.closest('.form-group')
    if (wrapper) {
      wrapper.classList.remove('required')
      wrapper.querySelectorAll('label').forEach(label => {
        label.classList.remove('required')
        label.classList.remove('form-label-required')
      })
      const abbr = wrapper.querySelector('abbr[title="required"]')
      if (abbr) {
        abbr.remove()
      }
    }
  }

  addRequired(element) {
    element.setAttribute('required', 'required')
    const wrapper = element.closest('.form-group')
    if (wrapper) {
      wrapper.classList.add('required')
      wrapper.querySelectorAll('label').forEach(label => {
        label.classList.add('required')
        label.classList.add('form-label-required')
        if (!label.querySelector('abbr')) {
          const abbr = document.createElement('abbr')
          abbr.title = 'required'
          abbr.textContent = ' *'
          label.appendChild(abbr)
        }
      })
    }
  }

  updateProfileLink(url) {
    const isValidLinkedInUrl = (url) => {
      // Verifica se é uma URL válida do LinkedIn com pelo menos um caractere após /in/
      return url.includes('linkedin.com/in/') && url.split('/in/')[1].length > 0
    }

    if (url && this.hasProfileLinkTarget) {
      // Formatar a URL corretamente
      let formattedUrl = url.trim()

      // Se já tem http/https, usa a URL como está
      if (!formattedUrl.startsWith('http')) {
        // Se já tem linkedin.com, adiciona apenas https
        if (formattedUrl.includes('linkedin.com')) {
          formattedUrl = `https://${formattedUrl}`
        } else {
          // Se é só o username, constrói a URL completa
          formattedUrl = formattedUrl.startsWith('/in/')
            ? `https://linkedin.com${formattedUrl}`
            : `https://linkedin.com/in/${formattedUrl.replace(/^\/+/, '')}`
        }
      }

      // Só mostra a verificação se for uma URL válida do LinkedIn
      if (isValidLinkedInUrl(formattedUrl)) {
        this.profileLinkTargets.forEach(link => {
          link.href = formattedUrl
        })

        if (this.hasLinkedinVerificationTarget && this.hasInitialInstructionsTarget) {
          this.linkedinVerificationTarget.classList.remove('d-none')
          this.initialInstructionsTarget.classList.add('d-none')
        }
      } else {
        // Se não for uma URL válida, mostra as instruções originais
        if (this.hasLinkedinVerificationTarget && this.hasInitialInstructionsTarget) {
          this.linkedinVerificationTarget.classList.add('d-none')
          this.initialInstructionsTarget.classList.remove('d-none')
        }
      }
    } else {
      // Quando não tem URL, mostra as instruções originais
      if (this.hasLinkedinVerificationTarget && this.hasInitialInstructionsTarget) {
        this.linkedinVerificationTarget.classList.add('d-none')
        this.initialInstructionsTarget.classList.remove('d-none')
      }
    }
  }

  checkJobStatus() {
    if (!this.hasPendingElement) return

    const checkStatus = () => {
      fetch(`/ats/${this.organizationParam}/candidates/onboarding/${this.onboardingId}/status`)
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            window.location.reload()
          } else if (!data.completed) {
            setTimeout(checkStatus, 2000)
          } else {
            window.location = data.redirect_url
          }
        })
    }

    setTimeout(checkStatus, 2000)
  }
}
