import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  filter(event) {
    const processOwnerId = event.target.value
    const url = new URL(window.location.href)

    // Remove page parameter when changing process owner
    url.searchParams.delete('page')

    if (processOwnerId) {
      url.searchParams.set('process_owner_id', processOwnerId)
    } else {
      url.searchParams.delete('process_owner_id')
    }

    window.location.href = url.toString()
  }
}
