import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "uploadArea", "selectedFile", "fileName", "fileStatus"]

  handleDragOver(event) {
    event.preventDefault()
    this.uploadAreaTarget.classList.add('dragover')
  }

  handleDragLeave(event) {
    event.preventDefault()
    this.uploadAreaTarget.classList.remove('dragover')
  }

  handleDrop(event) {
    event.preventDefault()
    this.uploadAreaTarget.classList.remove('dragover')
    const files = event.dataTransfer.files
    if (files.length) {
      this.inputTarget.files = files
      this.handleChange()
    }
  }

  handleChange() {
    const file = this.inputTarget.files[0]
    if (file) {
      this.fileNameTarget.textContent = file.name
      this.selectedFileTarget.classList.remove('d-none')
      this.fileStatusTarget.classList.add('d-none')
      this.uploadAreaTarget.classList.add('has-file')
    }
  }

  removeFile() {
    this.inputTarget.value = ''
    this.selectedFileTarget.classList.add('d-none')
    this.fileStatusTarget.classList.remove('d-none')
    this.uploadAreaTarget.classList.remove('has-file')
  }
}
