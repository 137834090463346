import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["options", "aiButton", "assessmentButton", "status"]
  static values = {
    companyHasAssessment: Boolean,
    jobHasAssessment: Boolean,
    companyId: String,
    companyName: String,
    jobId: String,
    ocaiAiPath: String,
    ocaiManualPath: String,
    personaAiPath: String,
    personaManualPath: String,
  }

  toggle(event) {
    const isChecked = event.target.checked

    this.optionsTarget.classList.toggle('d-none', !isChecked)
  }

  createOcaiWithAI(event) {
    event.preventDefault()

    this.postTo(this.ocaiAiPathValue, { company_id: this.companyIdValue, job_id: this.jobIdValue })

    event.currentTarget.classList.toggle('disabled')
    event.currentTarget.innerHTML = "<i class='fa-solid fa-robot me-2'></i> AI is working...";
  }

  createOcaiManually() {
    this.postTo(this.ocaiManualPathValue);
  }

  createPersonaWithAI() {
    event.preventDefault()

    this.postTo(this.personaAiPathValue, { job_id: this.jobIdValue });

    event.currentTarget.classList.toggle('disabled')
    event.currentTarget.innerHTML = "<i class='fa-solid fa-robot me-2'></i> AI is working...";
  }

  createPersonaManually() {
    this.postTo(this.personaManualPathValue);
  }



  async postTo(url, body = {}) {
    post(url, {
      responseKind: "turbo-stream",
      body: JSON.stringify(body),
    });
  }
}
